import {clientLog} from "../clientLog";

export const sanitizeKulturNavPersonData = data => {
    const excludeDatasets = window._env_.REACT_APP_PERSONS_EXCLUDE_DATASETS;
    if (!excludeDatasets || excludeDatasets?.length === 0) {
        clientLog('warn', 'persons exclude datasets arrays is undefined or empty', 'personssearchhandler');
        return data;
    }

    return data.filter(d => {
        return d.properties['entity.dataset'].find(ed => {
            return !excludeDatasets.includes(ed.value);
        })
    });
};