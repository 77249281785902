import Button from "@mui/material/Button";
import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ABTestDialog} from "../app/ABTestDialog";
import {CLOSE_AB_TEST_DIALOG, OPEN_AB_TEST_DIALOG, useAppDispatch} from "../app/AppContext";

import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import IconButton from "@mui/material/IconButton";
import {useMediaQuery} from "@mui/material";

/**
 * A button that, when clicked, either navigates to the chosen file uploader,
 * or allows the user to choose which uploader to use.
 *
 * If the user has previously chosen which uploader to use (by setting the
 * 'upload' field in localStorage), this button will navigate to the chosen
 * uploader. Otherwise, it will open an A/B-test dialog that allows the user
 * to choose which uploader to use.
 *
 * The button is wrapped in an effect that cleans up the A/B-test dialog when
 * the component is unmounted.
 *
 * @returns A JSX element containing the button and the A/B-test dialog.
 */
export const ButtonInvokeUpload = ({overrideSmallScreen}) => {

    const params = useParams();
    const navigate = useNavigate();
    const appDispatch = useAppDispatch();

    const [useNew, setUseNew] = useState();

    const smallScreen = useMediaQuery("(max-width: 768px)");

    const handleUploadClick = async () => {
        const savedPrefs = await JSON.parse(localStorage.getItem('dams.abtest.features'));
        setUseNew(savedPrefs?.upload);
        appDispatch({type: OPEN_AB_TEST_DIALOG});
    };

    const loadUploader = async () => {
        const savedPrefs = await JSON.parse(localStorage.getItem('dams.abtest.features'));
        let url = !savedPrefs?.upload ? '/upload' : '/upload3';
        const {folderId} = params;
        if (folderId) {
            url += `/${folderId}`;
        }
        navigate(url);
    };

    const abTestDialogCallback = () => {
        (async () => loadUploader())();
    };

    /**
     * Hook used to hide the A/B-test dialog upon exiting this component.
     */
    useEffect(() => {
        return () => {
            appDispatch({type: CLOSE_AB_TEST_DIALOG});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <>
        {(!smallScreen || overrideSmallScreen) &&
            <Button
                startIcon={<CloudUploadIcon/>}
                variant={"contained"}
                color={"primary"}
                onClick={async () => await handleUploadClick()}>
                Last opp filer
            </Button>
        }
        {smallScreen &&
            <IconButton color={"primary"}
                        variant={"contained"}
                        onClick={async () => await handleUploadClick()}>
                <CloudUploadIcon/>
            </IconButton>
        }
        <ABTestDialog callback={abTestDialogCallback}/>
    </>;
};