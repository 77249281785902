import {Dialog, DialogActions, DialogContent} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import {OperationFilesTable} from "./OperationFilesTable";
import {
    CANCEL_OPERATION,
    FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG,
    useAppDispatch,
    useAppState
} from "../../app/AppContext";
import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {BatchEditProvider} from "../../documents/batchEditContext";
import Button from "@mui/material/Button";
import {damsFetch} from "../../app/damsFetch";
import decamelizeKeysDeep from "decamelize-keys-deep";
import {clientLog} from "../../clientLog";
import {useNavigate} from "react-router-dom";
import {useDocumentTranslation} from "../../documents/documentContext";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * A dialog component that displays a list of files for the given operation.
 *
 * When the fileUploadOperationAllFiles state is set to true and the operationId
 * matches the operationId prop, the dialog is displayed.
 *
 * The dialog contains an OperationFilesTable component, which is used to display
 * the list of files and to edit the metadata of the selected files.

 * @returns {JSX.Element} A dialog component with a table of files.
 */
export const DialogListFiles = () => {
    const t = useDocumentTranslation();
    const {fileUploadOperationAllFilesDialog, operations} = useAppState();

    const [documentsFailedConversion, setDocumentsFailedConversion] = useState([]);
    const [operationId, setOperationId] = useState(undefined);
    const [dialogOpen, setDialogOpen] = useState(false);

    const appDispatch = useAppDispatch();
    const navigate = useNavigate();

    const [finishing, setFinishing] = useState(false);


    /**
     * Finds the operation with the given operationId in the list of operations.
     *
     * @return {object} The operation if found, or undefined if not found.
     */
    const getCurrentOperation = () => {
        return operations.find(o => o.jobId === operationId);
    };

    /**
     * Removes the specified operation from the list of operations.
     *
     * @param {object} operation - The operation to remove.
     */
    const removeOperationFromListOfOperations = operation => {
        appDispatch({type: CANCEL_OPERATION, operation: operation});
    };

    /**
     * Publishes the operation's content, and logs the given operation as complete to the server.
     *
     * If the operation is not found in the list of operations, a warning is logged.
     * Otherwise, a POST request is sent to the `/jobs/complete` endpoint with the
     * operation as a parameter.
     *
     * @returns {Promise<void>} A promise that resolves when the request is complete.
     */
    const publishOperation = async (operation) => {
        await damsFetch('/jobs/complete', {
            method: 'POST',
            body: decamelizeKeysDeep(JSON.stringify({
                operation: operation
            }))
        });
        clientLog('info', 'operation logged complete', 'fileupload');
    };

    /**
     * Completes the operation with the given operationId.
     *
     * Finds the operation in the list of operations, logs it as complete to the
     * server, and removes it from the list of operations.
     *
     * @returns {Promise<void>} A promise that resolves when the operation is
     * completed.
     */
    const completeOperation = async () => {
        const operation = getCurrentOperation();
        if (!operation) {
            clientLog('warning', 'operation not found, not published', 'fileupload');
        }
        await publishOperation(operation);
        removeOperationFromListOfOperations(operation);
    };

    /**
     * Closes the dialog for the given operation ID.
     *
     * Dispatches an action with type `FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG` and
     * sets the `open` property to `false` and the `operationId` property to the
     * given operation ID. Also sets the component state variable `dialogOpen` to
     * `false`.
     *
     * @returns {void}
     */
    const closeDialog = () => {
        appDispatch({
            type: FILE_UPLOAD_OPERATION_ALL_FILES_DIALOG,
            open: false,
            operationId: operationId
        });
        setDialogOpen(false);
    }

    /**
     * Triggers a refresh of the content by dispatching a search action with query '*'.
     *
     * This will cause the content to be reloaded from the server.
     *
     * @returns {void}
     */
    const refreshContent = () => {
        setFinishing(false);
        setTimeout(() => {
            navigate('/search', {});
        }, 200);
    };

    /**
     * Closes the dialog.
     *
     * Dispatches an action to close the dialog, and sets the local
     * `dialogOpen` state to false.
     */
    const handleCloseClick = async () => {
        setFinishing(true);
        await completeOperation();
        closeDialog();
        refreshContent();
    };

    /**
     * Hook used to open this dialog.
     */
    useEffect(() => {
        if (!fileUploadOperationAllFilesDialog) {
            return;
        }

        const {open, operationId, failedConversion} = fileUploadOperationAllFilesDialog;
        if (!open) {
            return;
        }
        setDocumentsFailedConversion(failedConversion);
        setOperationId(operationId);
        setDialogOpen(true);
    }, [fileUploadOperationAllFilesDialog, operationId]);

    return operationId &&
        <Box>
            <Dialog open={dialogOpen} fullScreen>
                <DialogTitle>{t('fileUploadDialogAddMetadataTitle', 'Legge til og redigere metadata')}</DialogTitle>
                <DialogContent sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    overflow: 'auto',
                    flexGrow: 1
                }}>
                    {t('fileUploadDialogAddMetadataText', 'Merk en eller flere linjer av gangen og rediger metadata. ' +
                        'Vi anbefaler å sette minimum tittel,fotograf/produsent, og emneord på alle objekter.')}
                    <BatchEditProvider>
                        <OperationFilesTable operationId={operationId} failedConversion={documentsFailedConversion}/>
                    </BatchEditProvider>
                </DialogContent>
                <DialogActions>
                    <Button
                        disabled={finishing}
                        startIcon={finishing && <CircularProgress size={16} color={"secondary"}/>}
                        variant={"contained"}
                        onClick={(async () => await handleCloseClick())}>
                        {t('btnComplete', 'Fullfør')}
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>;
};